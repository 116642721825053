<template>
    <ImageContent v-editable="blok" :data="imageContent" />
</template>

<script setup lang="ts">
import ImageContentType from '@/types/ImageContentType';
import ButtonType from '@/types/ButtonType';
import ImageType from '@/types/ImageType';
import ImageContent from '~/components/page-building/ImageContent.vue';
import { useImageStructuredData } from '~/composables/useStructuredData';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const buttons = props.blok.buttons
    ? props.blok.buttons.map((button: any) => {
          return {
              label: button.label,
              style: button.style,
              url: button.link.cached_url,
              vEditable: button,
          } as ButtonType;
      })
    : [];

const bulletPoints = props.blok.bullet_points
    ? props.blok.bullet_points.map((point: any) => {
          return {
              title: point.name,
              alt: point.alt,
              src: point.filename,
          } as ImageType;
      })
    : [];

const imageContent = computed<ImageContentType>(() => {
    return {
        image: {
            title: props.blok.image.title,
            alt: props.blok.image.alt,
            src: props.blok.image.filename,
        } as ImageType,
        heading: props.blok.heading,
        subheading: props.blok.subheading,
        description: richTextToHtml(props.blok.description) as string,
        buttons,
        bulletPoints,
        reverseLayout: props.blok.reverse_layout,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
        bottomAngle: props.blok.bottom_angle,
    };
});

const structuredData = useImageStructuredData(imageContent.value);

useServerHead(() => ({
    script: structuredData,
}));
</script>
